.footer{
  display: flex;
  height: 10vh;
  line-height: 1.3;
  font-family: Menlo, monospace;

}

.footer-tab {
  display: inline-grid;
  grid-auto-flow: row;
  grid-gap: 24px;
  justify-items: center;
  margin: auto;
}

@media (min-width: 500px) {
  .footer-tab {
    grid-auto-flow: column;
  }
}

.footer-link {
  color: white;
  text-decoration: none;
  box-shadow: inset 0 -1px 0 hsla(0, 0%, 100%, 0.4);
}

.footer-link :hover {
  box-shadow: inset 0 -1.2em 0 hsla(0, 0%, 100%, 0.4);
}

.footer-li:last-child {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.footer-li:hover ~ li p {
  animation: wave-animation 0.3s infinite;
}


