.nav{
    background-color: #000814;
    margin-right: 5%;
    margin-left: 3%;
}

.nav-link{
    color: #FFC300;
    background-color: #000814;
    
}

.navbar-brand{
    color: #FFC300;
    background-color: #000814;
}

.container{
    position: sticky;
}



