.title {
    align-self: center;
    justify-content: center;
    display: flex;
    margin-top: 10%;
    margin-bottom: 10%;
}

.link{
    text-decoration: none;
    color: #FFFF;
    
}
.linkdiv{
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 5%;
    font-size: 1.5em;
}