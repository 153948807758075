.texte{
    font-family: "Verdana", Times, serif;
    font-size: 1.2em;
    text-align: justify;
    margin: 0 10% 0 10%;
    padding: 0 10px 0 10px;
    color: #FFFF;
    margin-bottom: 5%;
    text-align: justify;
}

.title {
    align-self: center;
    align-items: center;
    margin-top: 10%;
    margin-bottom: 10%;
}

.paragraphe{
    margin-bottom: 30px;
    margin-top:30px
}

.image{
    margin-top: 30px;
    margin-bottom: 30px;
}

.btn-articles{
    align-self: center;
    align-items: center;
    margin-top: 10%;
    margin-bottom: 10%;
    justify-content: space-around;
    display:flex;
}

.btn-pages {
    width: 20%;
    height: 3em;
    font-size: 1.2em;
}

.tableau{
    margin-top: 30px;
    margin-bottom: 30px;
    margin-right: 30px;
    margin-left: 30px;
    border: 1px solid;
    color: white;
}

.case{
    width: 2000px;
}